import React from "react";

import * as styles from "./container.module.css";
import {cn} from "../lib/helpers";

const Container = ({ children }, {margins}) => {
  return <div className={styles.root}>{children}</div>;
};

export default Container;
